import ErrorService from '@/Services/ErrorService';
import { delay } from 'lodash-es';

export function retry(fn: Function, maxAttempts: number) {
  const execute: Function = async (attempt: number) => {
    try {
      return await fn();
    } catch (err) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;

        delay(() => execute(nextAttempt), 1500);
      } else {
        ErrorService.addError('retry', err);
      }
    }
  };

  return execute(1);
}
